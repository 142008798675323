import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as AOS from 'aos';
import {SEOService} from './service/seo.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Sonde} from './service/sonde';
import {EventManagerService, EventParams} from './service/event-manager.service';

declare var gtag: Function;
declare var fbq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent extends Sonde implements OnInit {
  private navControl: boolean;
  public callDropdown: Subject<void> = new Subject<void>();

  @ViewChild('cookieLaw')
  private cookieLawEl: any;

  @HostListener('click', ['$event'])
  @HostListener('touchmove', ['$event'])
  onMouseLeave(event) {
    this.navControl = true;
    event.path.forEach(element => {
      if (element.id) {
        if (element.id.toString().includes('topNav')) {
          this.navControl = false;
        } else if (!element.id.toString().includes('topNav') && this.navControl) {
          this.navControl = true;
        }
      }
    });

    if (this.navControl) {
      this.callDropdown.next();
    }
  }

  constructor(private router: Router, translate: TranslateService, private _seoService: SEOService,
              private activatedRoute: ActivatedRoute, eventManager: EventManagerService) {
    super(eventManager);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  public dismissCookie(): void {
    this.cookieLawEl.dismiss();
  }

  ngOnInit() {
    AOS.init({});

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        // Updating Description tag dynamically with title
        this._seoService.updateDescription(event['title'] + event['description']);
      });

    this.router.events.subscribe((ev) => {
      window.scrollTo(0, 0);
    });
    this.manageSonde();
  }

  sendEventGoogle(category: any, action: any) {
    gtag('event', action, {
      event_category: category
    });
  }

  manageSonde() {
    let _self = this;
    this.eventManager.observable.subscribe((event: EventParams) => {
      _self.sendEventGoogle(event.name, event.name);
      fbq('trackCustom', event.name);
    });
  }
}

import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class AppService {
  public language = `it`;
  public apiUrl = 'https://d3pfagrio8dq8t.cloudfront.net/api/';

  constructor(private http: Http) {
  }

  public setLanguage(culture: string) {
    this.language = culture;
  }

  public getMenuContext() {
    return this.http.get('./assets/json/navbar.json');
  }

  public getHomeContext() {
    return this.http.get('./assets/json/home.json');
  }

  public getBannerContext() {
    return this.http.get('./assets/json/banner.json');
  }

  public getFooterContext() {
    return this.http.get('./assets/json/footer.json');
  }

  // News
  public getNews() {
    return this.http.get(`${this.apiUrl}news/list`);
  }

  public getNewsById(id: string) {
    return this.http.get(`${this.apiUrl}news/${id}`);
  }

  // Careers
  public getCareers() {
    return this.http.get(`${this.apiUrl}announces/list`);
  }

  public getCareersById(id: string) {
    return this.http.get(`${this.apiUrl}announces/${id}`);
  }

  public getPress() {
    return this.http.get(`${this.apiUrl}press/list`);
  }

  public getPressById(id: string) {
    return this.http.get(`${this.apiUrl}press/${id}`);
  }

  public getCompanyId() {
    return this.http.get('./assets/json/company.json');
  }

  public getPortfolio() {
    return this.http.get('./assets/json/portfolio.json');
  }

  public getService() {
    return this.http.get('./assets/json/service.json');
  }

  public getTechnologies() {
    return this.http.get('./assets/json/tecnologie.json');
  }

  public getApplication() {
    return this.http.get(`${this.apiUrl}applications/list`);
  }

  public getWorks() {
    return this.http.get(`${this.apiUrl}careers_category/list`);
  }

  public getContactGallery() {
    return this.http.get('./assets/json/contactGallery.json');
  }

  public getMobileBanner() {
    return this.http.get('./assets/json/home-mobile.json');
  }

  public getCompanies() {
    return this.http.get('./assets/json/home-societa.json');
  }

  public getTeam() {
    return this.http.get('./assets/resources/team/team.json');
  }

  public uploadCVthelabsite(name, mail, about, file) {
    let headersParams = new Headers();
    headersParams.append('nome', encodeURI(name));
    headersParams.append('email', mail);
    headersParams.append('messaggio', encodeURI(about));
    headersParams.append('Accept', 'application/octet-stream');
    let options = new RequestOptions({headers: headersParams});
    let url = `https://hw87z8vmr0.execute-api.us-west-2.amazonaws.com/prod/uploadthelabsite/${file.name}`;
    return this.http.put(encodeURI(url), file, options);
  }

  public sendEmailContacts(formContact: any) {
    let url = 'https://hw87z8vmr0.execute-api.us-west-2.amazonaws.com/prod/contactus-thelabsite';
    return this.http.post(url, formContact);
  }

}

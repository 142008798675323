import {Subject} from 'rxjs';
import {OnInit} from '@angular/core';
import {EventManagerService, EventParams} from './event-manager.service';

export const LOGO_THELAB = 'TheLabLogo';
export const NAVBAR_HOME = 'Home';
export const NAVBAR_APP = 'App';
export const NAVBAR_SOCIAL_MARKETING = 'SocialMarketing';
export const NAVBAR_VISIONBOOKS = 'Visionbooks';
export const NAVBAR_FACESTAR = 'Facestar';
export const NAVBAR_LUMYER = 'Lumyer';
export const NAVBAR_MANIFESTO = 'Manifesto';
export const NAVBAR_TEAM = 'Team';
export const NAVBAR_CAREER = 'Career';
export const CAREER_UPLOAD = 'UploadCV';
export const CAREER_SEND = 'SendCV';
export const NAVBAR_CONTACT = 'ContactUs';
export const CONTACT_SEND = 'ContactSend';
export const NAVBAR_LINKEDIN = 'Linkedin';
export const NAVBAR_FACEBOOK = 'Facebook';
export const NAVBAR_INSTAGRAM = 'Instagram';
export const PRESS_LINK = 'AppPressCounter';
export const FACESTAR_CONTACT = 'FacestarContacUs';
export const FACESTAR_WEBSITE = 'FacestarWebsiteLink';
export const FACESTAR_APP_STORE = 'FacestarAppStore';
export const FACESTAR_PLAY_STORE = 'FacestarGooglePlay';
export const LUMYER_CONTACT = 'LumyerContactUs';
export const LUMYER_WEBSITE = 'LumyerWebsiteLink';
export const LUMYER_APP_STORE = 'LumyerAppStore';
export const LUMYER_PLAY_STORE = 'LumyerGooglePlay';
// Footer
export const FOOTER_HOME = 'FooterHome';
export const FOOTER_APP = 'FooterApp';
export const FOOTER_SOCIAL = 'FooterSocialMarketing';
export const FOOTER_VISIONBOOKS = 'FooterVisionbooks';
export const FOOTER_FACESTAR = 'FooterFacestar';
export const FOOTER_LUMYER = 'FooterLumyer';
export const FOOTER_MANIFESTO = 'FooterManifesto';
export const FOOTER_TEAM = 'FooterLink';
export const FOOTER_CAREEER = 'FooterCareer';
export const FOOTER_CONTACT = 'FooterContact';
export const FOOTER_PRIVACY = 'FooterPrivacy';
export const FOOTER_LINKEDIN = 'FooterLinkedIn';
export const FOOTER_FACEBOOK = 'FooterFacebook';
export const FOOTER_INSTAGRAM = 'FooterInstagram';

export class Sonde implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public eventManager: EventManagerService) {
  }

  ngOnInit() {
    console.log('init');
  }

  onEventSonde(type: string, params: any = null) {
    const startEvent = new EventParams();
    startEvent.name = type;
    startEvent.params = params;
    this.eventManager.broadcast(startEvent);
  }
}

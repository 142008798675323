import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {AppService} from '../app.service';
import {Menu} from '../entity.class';
import {
  LOGO_THELAB,
  NAVBAR_APP,
  NAVBAR_CAREER,
  NAVBAR_CONTACT,
  NAVBAR_FACEBOOK,
  NAVBAR_FACESTAR,
  NAVBAR_HOME,
  NAVBAR_INSTAGRAM,
  NAVBAR_LINKEDIN,
  NAVBAR_LUMYER,
  NAVBAR_MANIFESTO,
  NAVBAR_SOCIAL_MARKETING,
  NAVBAR_TEAM,
  NAVBAR_VISIONBOOKS,
  Sonde
} from 'app/service/sonde';
import {EventManagerService} from 'app/service/event-manager.service';

declare var $;

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent extends Sonde implements OnInit {

  @ViewChild('navbarButton') navbarButton: ElementRef;
  @ViewChild('navbarDropdown') dropdown: ElementRef;
  @Output() dropdownActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() callDropdown: Subject<void>;
  @Input() closeDropdown: Subject<boolean>;

  public Logo: any = document.getElementById('Logo');
  public topNav: any = document.getElementById('topNav');
  public descLogo: any = document.getElementById('descLogo');
  public container: any = document.getElementById('cont');

  menuContext: Array<Menu> = new Array<Menu>();
  classFixed: boolean = false;
  classShown: boolean = true;

  @HostListener('window: scroll', [])

  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.classFixed = true;
      this.classShown = false;
    } else {
      this.classFixed = false;
      this.classShown = true;
    }
  }

  constructor(
    public appService: AppService,
    public router: Router,
    private el: ElementRef,
    private _ngZone: NgZone,
    eventManager: EventManagerService
  ) {
    super(eventManager);
  }

  ngOnInit() {
    const _self = this;
    this.appService.getMenuContext().subscribe(x => {
      let tempMenu = x.json();
      tempMenu.forEach(element => {
        if (element.url !== 'team') _self.menuContext.push(element);
      });
      // this.menuContext = x.json()
    });
    $(document).ready(() => {
      $('.solutions').click(() => {
        this.hideNavbar();
      });
      $('li .main').click(() => {
        this.hideNavbar();
      });
    });
    // hide navbar onclick in Mobile
    $('.nav a').on('click', () => {
      $('.navbar-toggle').click();
    });
    // Hide on click outside navbar
    $(document).click((event) => {
      let clickover = $(event.target);
      let $navbar = $('.navbar-collapse');
      let _opened = $navbar.hasClass('in');
      if (_opened === true && !clickover.hasClass('navbar-toggle')) {
        $navbar.collapse('hide');
      }
    });

    this.Logo = document.getElementById('Logo');
    this.topNav = document.getElementById('topNav');
    this.descLogo = document.getElementById('descLogo');
    this.container = document.getElementById('cont');
  }

  private hideNavbar() {
    $('.navbar-collapse').collapse('hide');
  }

  getSelected(element: any) {
    let tempElement: string = '' + element;
    return this.router.url.indexOf(tempElement) !== -1;
  }

  logo() {
    this.onEventSonde(LOGO_THELAB);
  }

  navbar_click(title: string) {
    switch (title.toLowerCase()) {
      case 'home':
        this.onEventSonde(NAVBAR_HOME);
        break;
      case 'app':
        this.onEventSonde(NAVBAR_APP);
        break;
      case 'social marketing':
        this.onEventSonde(NAVBAR_SOCIAL_MARKETING);
        break;
      case 'visionbooks':
        this.onEventSonde(NAVBAR_VISIONBOOKS);
        break;
      case 'facestar':
        this.onEventSonde(NAVBAR_FACESTAR);
        break;
      case 'lumyer':
        this.onEventSonde(NAVBAR_LUMYER);
        break;
      case 'manifesto':
        this.onEventSonde(NAVBAR_MANIFESTO);
        break;
      case 'team':
        this.onEventSonde(NAVBAR_TEAM);
        break;
      case 'career':
        this.onEventSonde(NAVBAR_CAREER);
        break;
      case 'contact':
        this.onEventSonde(NAVBAR_CONTACT);
        break;
      default:
        break;
    }
  }

  linkedin() {
    this.onEventSonde(NAVBAR_LINKEDIN);
  }

  facebook() {
    this.onEventSonde(NAVBAR_FACEBOOK);
  }

  instagram() {
    this.onEventSonde(NAVBAR_INSTAGRAM);
  }
}

import {Component, OnInit} from '@angular/core';
import {Http} from '@angular/http';
import {NavigationEnd, Router} from '@angular/router';
import 'rxjs/Rx';

declare let $: any;
declare var ga: Function;

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  ok: any;
  link: any;
  annuncio: any;
  nome: string;
  cognome: string;
  phone: any;
  email: string;
  messaggio: string;
  // = 'TEST FROM PROPOSTA1';
  myfile: any;
  timestamp: Date = new Date();
  userId: any = this.timestamp.getTime();

  constructor(
    private http: Http,
    private router: Router) {
    router.events.distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    }).subscribe((x: any) => {
      ga('send', 'pageview', x.url);
    });
  }

  ngOnInit() {
    this.apiGet();
    this.modalUp();
  }

  apiGet() {
    this.http.get('/assets/data/annuncio.json')
      .map(res => res.json())
      .subscribe(data => {
        this.annuncio = data;
        let mythis = this;
        $(document).ready(() => {
          mythis.onLayout();
        });
      });
  }

  modalUp() {
    $('#myModal2').modal('show');
  }

  onLayout() {
    $(`#wrapper`).dotdotdot({
      after: 'a.readmore',
      height: 170
    });

    $('a.readmore').on('click', function(event) {

      if ($(window).width() <= 767) {
        $(this).parent().trigger('destroy');
      } else {
        $('#myModal').modal('show');
      }
      event.preventDefault();
    });
  }

  modal_privacy() {
    $('#modal_privacy').modal('show');
  }

  public goUlixe() {
    window.location.href = 'http://www.ulixe.com/';
  }

}

import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Footer} from '../entity.class';
import {
  FOOTER_APP,
  FOOTER_CAREEER,
  FOOTER_CONTACT,
  FOOTER_FACEBOOK,
  FOOTER_FACESTAR,
  FOOTER_HOME,
  FOOTER_INSTAGRAM,
  FOOTER_LINKEDIN,
  FOOTER_LUMYER,
  FOOTER_MANIFESTO,
  FOOTER_PRIVACY,
  FOOTER_SOCIAL,
  FOOTER_TEAM,
  FOOTER_VISIONBOOKS,
  Sonde
} from 'app/service/sonde';
import {EventManagerService} from 'app/service/event-manager.service';

@Component({
  selector: 'custom-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends Sonde implements OnInit {

  footerContext: Array<Footer> = new Array<Footer>();

  constructor(public appService: AppService, eventManager: EventManagerService) {
    super(eventManager);
  }

  ngOnInit() {
    const _self = this;
    this.appService.getFooterContext().subscribe(x => {
      let tempMenu = x.json();
      tempMenu.forEach(element => {
        if (element.url !== 'team') _self.footerContext.push(element);
      });
      // this.footerContext = x.json()
    });
  }

  footer_click(title: string) {
    switch (title.toLowerCase()) {
      case 'home':
        this.onEventSonde(FOOTER_HOME);
        break;
      case 'app':
        this.onEventSonde(FOOTER_APP);
        break;
      case 'social marketing':
        this.onEventSonde(FOOTER_SOCIAL);
        break;
      case 'visionbooks':
        this.onEventSonde(FOOTER_VISIONBOOKS);
        break;
      case 'facestar':
        this.onEventSonde(FOOTER_FACESTAR);
        break;
      case 'lumyer':
        this.onEventSonde(FOOTER_LUMYER);
        break;
      case 'manifesto':
        this.onEventSonde(FOOTER_MANIFESTO);
        break;
      case 'team':
        this.onEventSonde(FOOTER_TEAM);
        break;
      case 'career':
        this.onEventSonde(FOOTER_CAREEER);
        break;
      case 'contact':
        this.onEventSonde(FOOTER_CONTACT);
        break;
      case 'privacy & policy':
        this.onEventSonde(FOOTER_PRIVACY);
        break;

      default:
        break;
    }
  }

  linkedin() {
    this.onEventSonde(FOOTER_LINKEDIN);
  }

  facebook() {
    this.onEventSonde(FOOTER_FACEBOOK);
  }

  instagram() {
    this.onEventSonde(FOOTER_INSTAGRAM);
  }

}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [

  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: 'home', loadChildren: './theLab-home/theLab-home.module#TheLabHomeModule',
    data: {
      title: 'THELAB - Home',
      description: 'Siamo una startup torinese specializzata in applicazioni web e mobile. Progettiamo, sviluppiamo e ' +
        'promuoviamo app usate da milioni di utenti in tutto il mondo. Realizziamo app in modalità agile per aziende e startup.',
      ogUrl: 'https://www.thelab.site/home'
    }
  },
  {
    path: 'manifest', loadChildren: './theLab-manifesto/theLab-manifesto.module#TheLabManifestoModule',
    data: {
      title: 'THELAB - Manifest',
      description: 'Ogni giorno cerchiamo di conquistare il mondo e, nel frattempo, di non far crashare tutto prima della release.',
      ogUrl: 'https://www.thelab.site/manifest'
    }
  },
  // {
  //   path: 'team', loadChildren: './thelab-team/theLab-team.module#TheLabTeamModule',
  //   data: {
  //     title: 'THELAB - Team',
  //     description: 'Dietro ogni grande app, c’è un grande team. Il nostro segreto? Il giusto mix di sviluppatori, ' +
  //       'creativi, marketers e… cani.',
  //     ogUrl: 'https://www.thelab.site/team'
  //   }
  // },
  {
    path: 'app', loadChildren: './applications/applications.module#ApplicationsModule',
    data: {
      title: 'THELAB - App',
      description: 'Scopri il mondo di TheLab. I nostri progetti. Le nostre App. Le nostre soluzioni. La nostra passione.',
      ogUrl: 'https://www.thelab.site/app'
    }
  },
  {
    path: 'careers', loadChildren: './lavora-con-noi/lavora-con-noi.module#LavoraConNoiModule',
    data: {
      title: 'THELAB - Careers',
      description: 'Cerchiamo costantemente gente sveglia, Junior e Senior. Vuoi entrare nel team? Inviaci il tuo CV e parlaci di te!',
      ogUrl: 'https://www.thelab.site/careers'
    }
  },
  {
    path: 'lumyer', loadChildren: './lumyer/lumyer.module#LumyerModule',
    data: {
      title: 'THELAB - Lumyer',
      description: 'Anima le tue foto con i 400 filtri di Lumyer, l’app scaricata da oltre 30 milioni di utenti. ',
      ogUrl: 'https://www.thelab.site/lumyer'
    }
  },
  {
    path: 'facestar', loadChildren: './facestar/facestar.module#FacestarModule',
    data: {
      title: 'THELAB - Facestar',
      description: 'Vuoi diventare il protagonista dei tuoi film preferiti? FaceStar trasforma il tuo selfie in una scena da Oscar.',
      ogUrl: 'https://www.thelab.site/facestar'
    }
  },
  {
    path: 'contact', loadChildren: './contact/contact.module#ContactModule',
    data: {
      title: 'THELAB - Contact',
      description: 'Vuoi saperne di più? Se sei a Torino, parti dalla Mole e vai dritto fino alla Dora. Altrimenti, inviaci una mail.',
      ogUrl: 'https://www.thelab.site/contact'
    }
  },
  {
    path: 'gdpr', loadChildren: './gdpr/gdpr.module#GdprModule',
    data: {
      title: 'THELAB - gdpr',
      description: 'We foster digital business, we design APP and mobile solution for scale',
      ogUrl: 'https://www.thelab.site/gdpr'
    }
  },
  {
    path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule',
    data: {
      title: 'THELAB - NOT - FOUND',
      description: 'We foster digital business, we design APP and mobile solution for scale',
      ogUrl: 'NOT FOUND'
    }
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
